

import {Component, Vue} from "vue-property-decorator";
import {TaskDurum} from "@/plugins/uyap-plugin/enum/TaskDurum";
import {ITask, ITasks, PlayerDurum, Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import FormButton from "@/components/dialogs/FormButton.vue";
import TakipAcTebligatGideriForm from "@/components/eicrapro/TakipAcTebligatGideriForm.vue";
import {OdemeTipiEnum} from "@/plugins/uyap-plugin/takip_ac/OdemeTipleri";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {saveAs} from "file-saver";
import {TakipTalebiIndirCevap} from "@/plugins/uyap-plugin/uyap/TakipTalebiIndir";
import {Socket} from "vue-socket.io-extended";
import {TamamlanmayanDosyalarRow} from "@/plugins/uyap-plugin/uyap/TamamlanmayanDosyalar";

@Component({
  components: {FormDialog, TakipAcTebligatGideriForm, FormButton}
})
export default class TakipHazirlaIslemDetayView extends Vue {
  @Socket('updateTasks')
  onUpdateTask() {
    this.load();
  }

  onFly = false;
  downloading = false;
  ttDownloading = false;
  seciliGorevler: Array<Task> = [];
  headers = [
    {text: 'Alacaklılar', value: 'alacaklilar'},
    {text: 'Borçlular', value: 'borclular'},
    {text: 'Takibe Esas Tutar', value: 'toplamTakibeEsasMiktar'},
    {text: 'Harç + Masraf', value: 'harcMasraf', sortable: false},
    {text: 'Tebligat Gideri Öde', value: 'tebligatGideri', sortable: false},
    {text: 'Açıklama', value: 'description'},
    {text: 'Durum', value: 'status'},
    {text: 'İşlemler', value: 'actions'}
  ];
  seciliHataliGorevler = [];
  loading = false;
  hataliHeaders = [
    {text: 'Alacaklılar', value: 'alacaklilar'},
    {text: 'Borçlular', value: 'borclular'},
    {text: 'Takibe Esas Tutar', value: 'toplamTakibeEsasMiktar'},
    {text: 'Açıklama', value: 'description'},
    {text: 'Durum', value: 'status'},
  ];
  taskDurum = TaskDurum;
  odemeTipiEnum = OdemeTipiEnum;
  items: Array<ITask> = [];
  mountedLoad = false;
  programPaths = ProgramPaths;
  playerDurum = PlayerDurum;

  get isFirstTask(): boolean {
    return this.$store.getters.getSiradakiGorevler[0]?.id == this.$route.params.id;
  }

  get paternTask(): ITask {
    let tasks: ITasks = this.$store.getters.getGorevler;
    let allTaskt: Array<ITask> = [...tasks.incomplete_tasks, ...tasks.completed_tasks];
    return allTaskt.find((task: ITask) => task.id == Number(this.$route.params.id)) as ITask;
  }

  async mounted() {
    await this.load();
  }

  async load() {
    // storedaki görevlerin güncellenmesi durumunda tebligat ve ödeme bilgilerinin gitmemesi için cache alınıyor
    try {
      this.loading = true;
      let storeItems = (await this.$http.get('/api/v1/task/' + this.$route.params.id + '/child-tasks')).data;
      if (!this.mountedLoad && storeItems) {
        this.items = storeItems;
        this.mountedLoad = true;
      } else if (this.mountedLoad) {
        this.items.forEach((item, index) => {
          Object.assign(item, storeItems[index]);
        })
      }
    } finally {
      this.loading = false;
    }
  }

  get toplamHarcMasraf() {
    let toplam = this.items.reduce((acc, item) => acc + item.data.harcMasrafToplam, 0);
    return toplam;
  }

  downloadExcel() {
    this.downloading = true;
    this.$helper.download('Excel Sonuçlar.xlsx', '/api/v1/export-to-excel/' + this.$route.params.id).finally(() => {
      this.downloading = false;
    });
  }

  tarihiFormatla(tarih: string) {
    // dateStr -> 09/05/2024 10:43:06
    const parts = tarih.split(' ');             // Boşluklardan böl
    const dateParts = parts[0].split('/');      // Tarihi '/' işaretlerinden böl
    const timeParts = parts[1].split(':');      // Saati ':' işaretlerinden böl
    tarih = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${timeParts.join(':')}`;
    return tarih;
  }

  async topluTTIndir() {
    this.ttDownloading = true;
    try {
      let params: Array<{
        birimId: string,
        acilisTime: number
      }> = this.items.filter(((item: ITask) => item.status == this.taskDurum.BITTI_BASARILI)).map((item: ITask) => ({
        birimId: item.response.tevziBilgileri.birimID,
        acilisTime: new Date(this.tarihiFormatla(item.response.tevziBilgileri.dosyaAcilisTarihi)).getTime()
      }));
      let dosyalar: Array<TamamlanmayanDosyalarRow> = await this.$uyap.TamamlanmayanDosyaBul().runToplu(params);
      let uyapIdleri = dosyalar.map((item: TamamlanmayanDosyalarRow) => ({dosyaId: item.dosyaID}))
      await this.$uyapHelper.topluTakipTalebiIndir(uyapIdleri);
    } catch (e) {
      console.error('topluTTIndir Error', e.message);
    } finally {
      this.ttDownloading = false;
    }
  }

  async ttIndir(dosya: ITask) {
    let time = new Date(dosya.uyap_dosya_acilis_tarihi).getTime();
    let dosyaBul = await this.$uyap.TamamlanmayanDosyaBul().run({birimId: dosya.birim_id, acilisTime: time});
    let blobData: TakipTalebiIndirCevap = await this.$uyap.TakipTalebiIndir(dosyaBul.dosyaID).run({
      download: true,
      filename: "takip_talebi.udf"
    });
    saveAs(blobData.file, "Takip Talebi_" + time + ".udf");
  }

  async durdurDevamEt() {
    if (this.$store.getters.getPlayerDurum == this.playerDurum.oto) {
      this.$socket.client.emit("player", this.playerDurum.durduruluyor);
    } else {
      this.$socket.client.emit("player", this.playerDurum.oto);
    }
  }

  selectAllToggle() {
    let tamamlananlar = this.items.filter((item:ITask) => item.status == this.taskDurum.BITTI_BASARILI);
    if (this.seciliGorevler.length == tamamlananlar.length) {
      this.seciliGorevler = [];
    } else {
      this.seciliGorevler = tamamlananlar;
    }
  }

  async addAllTebligatGideri(value: any) {
    let data={
      ids: this.seciliGorevler.map(seciliGorev=> seciliGorev.id),
      tebligat_gideri: {
        tutar: value?.tabligatGideri.tutar,
        aciklama: value?.tabligatGideri.aciklama,
        enabled: value?.tabligatGideri.enabled
      }
    }
    await this.$store.dispatch('tebligatGideriGuncelle', data);
  }

  async tebligatGideriChange(item: any){
    if(item.status === this.taskDurum.BITTI_BASARILI)
      item.response.parsedFile.formData.tebligatGideri = item.data.formData.tebligatGideri;
    await this.$store.dispatch('putTask', item);
    await this.load();
  }

  async createTask() {
    if (this.seciliGorevler.length > 0) {
      let tasks: Array<Task> = [];
      this.seciliGorevler.forEach((item: any) => {
        let task: Task = new Task();
        task.birim_adi = item.response.tevziBilgileri.birimAdi ?? "";
        task.birim_id = item.response.tevziBilgileri.birimID;
        task.uyap_dosya_acilis_tarihi = this.tarihiFormatla(item.response.tevziBilgileri.dosyaAcilisTarihi);
        task.dosya_id = item.dosyaID;
        task.task_type_id = TaskTuru.takip_ac;
        task.data = item.response;
        tasks.push(task);
      });
      await this.$store.dispatch('postTasks', tasks);
      await this.$router.push(ProgramPaths.eicrapro + '/islemler');
    }
  }

  selectAllErrorsToggle() {
    let hatalilar = this.items.filter((item:ITask) => (item.status == this.taskDurum.BITTI_HATALI || item.status == this.taskDurum.BITTI_EKSIK));
    if (this.seciliHataliGorevler.length == hatalilar.length) {
      this.seciliHataliGorevler = [];
    } else {
      this.seciliHataliGorevler = hatalilar;
    }
  }

  async tryAgainCreateTask() {
    if (this.seciliHataliGorevler.length > 0) {
      this.onFly = true;
      let tasks: Array<ITask> = [];
      this.seciliHataliGorevler.forEach((item: any) => {
        let task: ITask = {...item};
        task.id = null;
        task.status = TaskDurum.SIRADA;
        task.response = null;
        task.parent_id = null;
        tasks.push(task);
      });
      tasks[0].aciklama = tasks[0].data.xmlName + " XML'indeki hatalı takiplerin yeniden tevzi alınması."
      try {
        await this.$store.dispatch('postTasks', tasks);
        let req = { ids: this.seciliHataliGorevler.map((gorev: ITask) => gorev.id), status: TaskDurum.YENIDEN_BASLATILDI};
        await this.$http.post('/api/v1/task/update-status', req);
        await this.$store.dispatch('getTasksFromServer');
        await this.load();
        await this.$router.push(ProgramPaths.eicrapro + '/islemler');
      } catch (e) {
        console.error(e)
      } finally {
        this.seciliHataliGorevler = [];
        this.onFly = false;
      }
    } else {
      this.$toast.info('Yeniden başalatılacak görevleri seçiniz.')
    }
  }
}
